import React, { useState } from 'react'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { _changePassword } from '../../api/common';

function ChangePassword() {
    const [passwordData, setPasswordData] = useState({
        old_password: "",
        new_password: "",
        confirm_password: "",
    })

    const handlePasswordData = (event) => {
        const { name, value } = event.target;

        setPasswordData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangePassword = async () => {
        if (passwordData.old_password === "" && passwordData.new_password === "" || passwordData.confirm_password === "") {
            toast.error("Old Password, New Password or Confirm Password can't be blank");
            return;
        }

        if (passwordData.new_password !== passwordData.confirm_password) {
            toast.error("New Password and Confirm Password are not same");
            return;
        }

        const data = {
            old_password: passwordData?.old_password,
            new_password: passwordData?.new_password
        };

        try {
            const res = await _changePassword(data);
            console.log(res)
            if (res.data.status) {
                toast.success("Password Changed Successfully")
                setPasswordData({
                    old_password: "",
                    new_password: "",
                    confirm_password: "",
                });
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message);
        }
    };

    return (
        <>
            <Grid container spacing={3} style={{ marginTop: "10px" }}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            pb: 4,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <h1>Change Password </h1>
                        <p>Update your password here</p>
                        <br />

                        <Grid xs={12}>
                            <label>
                                <b>Enter Old Password</b>
                            </label>
                            <br />
                            <TextField
                                style={{ marginBlock: "10px", width: "100%" }}
                                label="Old Password"
                                size="small"
                                id="outlined-end-adornment"
                                sx={{ mb: 1, mr: 1, width: "25ch" }}
                                name="old_password"
                                onChange={handlePasswordData}
                                value={passwordData?.old_password}
                            />
                        </Grid>

                        <Grid xs={12}>
                            <label>
                                <b>Enter New Password</b>
                            </label>
                            <br />
                            <TextField
                                style={{ marginBlock: "10px", width: "100%" }}
                                label="New Password"
                                size="small"
                                id="outlined-end-adornment"
                                sx={{ mb: 1, mr: 1, width: "25ch" }}
                                name="new_password"
                                onChange={handlePasswordData}
                                value={passwordData?.new_password}
                            />
                        </Grid>

                        <Grid xs={12}>
                            <label>
                                <b>Confirm New Password</b>
                            </label>
                            <br />
                            <TextField
                                style={{ marginBlock: "10px", width: "100%" }}
                                label="Confirm Password"
                                size="small"
                                id="outlined-end-adornment"
                                sx={{ mb: 1, mr: 1, width: "25ch" }}
                                name="confirm_password"
                                onChange={handlePasswordData}
                                value={passwordData?.confirm_password}
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            sx={{
                                mt: 1,
                                display: "flex",
                                justifyContent: "end",
                            }}
                        >
                            <Button
                                style={{
                                    marginBlock: "10px",
                                    width: "35ch",
                                }}
                                variant="contained"
                                className=""
                                onClick={() => handleChangePassword()}
                            >
                                Submit
                            </Button>
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default ChangePassword