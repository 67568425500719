export const endPoint = process.env.REACT_APP_API_URL;

const conversation = endPoint + "/conversation";
const videoLinks = endPoint + "/all-links";
const login = endPoint + "/login";
const register = endPoint + "/register";
const followUpMessages = endPoint + "/followup";
const currentUserDetails = endPoint + "/user/profile";
const addVideoLink = endPoint + "/add-video-link";
const addArticleLink = endPoint + "/add-link";
const updateVideoLink = endPoint + "/update-video-link";
const deleteVideoLink = endPoint + "/delete-video-link";
const updateLink = endPoint + "/update-link";
const deleteLink = endPoint + "/delete-link";
const stripeSession = endPoint + "/create-checkout-session"
const addGoogleAd = endPoint + "/add-google-ad"
const getGoogleAds = endPoint + "/get-google-ads"
const getAudio = endPoint + "/get-audio";
const getTextAudio = endPoint + "/get-text-audio";
const getSessionMessages = endPoint + "/get-messages";
const updateGoogleAds = endPoint + "/update-google-ad";
const deleteGoogleAds = endPoint + "/delete-google-ad";
const subscriptionStatus = endPoint + "/subscription-status";
const browseLinks = endPoint + "/browse";
const changePassword = endPoint + "/change_password";


export const endPoints = {
  conversation,
  videoLinks,
  login,
  register,
  followUpMessages,
  currentUserDetails,
  addVideoLink,
  addArticleLink,
  updateVideoLink,
  deleteVideoLink,
  updateLink,
  deleteLink,
  stripeSession,
  addGoogleAd,
  getGoogleAds,
  getAudio,
  getTextAudio,
  getSessionMessages,
  updateGoogleAds,
  deleteGoogleAds,
  subscriptionStatus,
  browseLinks,
  changePassword
};
