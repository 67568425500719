import React, { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { adsHead } from "../../utils/constants";
import {
  _addGoogleAd,
  _deleteGoogleAd,
  _getGoogleAds,
  _updateGoogleAd,
} from "../../api/common";
import { handleCopy } from "../../utils/methods";
import DoneIcon from "@mui/icons-material/Done";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function Ads() {
  const [adLink, setAdLink] = useState("");
  const [adPreviewImg, setAdPreviewImg] = useState("");
  const [adFile, setAdFile] = useState(null);
  const [isAdUpdate, setIsAdUpdate] = useState(false);
  const [adDetails, setAdDetails] = useState([]);
  const [copied, setCopied] = useState(false);
  const [adId, setAdId] = useState("");
  const fileRef = useRef()

  const previewAdImage = () => {
    if (adFile) {
      let reader = new FileReader();
      reader.readAsDataURL(adFile);

      reader.onload = (e) => {
        let img = e.target.result;
        setAdPreviewImg(img);
      };
    }
  };

  const getGoogleAds = () => {
    _getGoogleAds("right").then((resp) => {
      if (resp.status === 200) {
        // setVideos(resp?.data?.video_link);
        // setBlogLinks(resp?.data?.web_link);
        setAdDetails(resp?.data?.data);
      } else {
        console.err(resp);
      }
    });
  };

  const addAdLink = async () => {
    // const data = { ...adFormData };
    const formData = new FormData();
    formData.append("ad_image", adFile);
    formData.append("link", adLink);
    formData.append("position", "right");

    // data["token"] = localStorage.getItem("access_token");

    if (adFile === "" || adLink === "") {
      alert("Video Link or Video description can't be blank");
      return;
    }

    try {
      const res = await _addGoogleAd(formData);
      if (res.status === 200) {
        console.log("record saved successfully");
        setIsAdUpdate(false);
        setAdLink("");
        setAdPreviewImg("");
        fileRef.current.value = '';
        setAdFile('')
        getGoogleAds();
      }
    } catch (error) {}
  };
  const updateGoogleAdLink = async () => {
    if (adLink === "" || adFile === "") {
      alert("Video Link or Video description can't be blank");
      return;
    }
    const formData = new FormData();

    if (adFile !== "" && adFile !== null) {
      formData.append("ad_image", adFile);
    }
    
    formData.append("link", adLink);
    // let data = {
    //   url: adFormData?.url,
    //   description: adFormData?.description,
    // };
    let id = adId
    try {
      const res = await _updateGoogleAd(formData, id);
      if (res.status === 200) {
        alert("Record updated Successfully");
        setIsAdUpdate(false);
        setAdLink("");
        setAdPreviewImg("");
        // setVideoFormData({
        //   description: "",
        //   url: "",
        //   id: null,
        // });
        getGoogleAds();
      } else {
        alert("Error Updating Record");
      }
    } catch (err) {
      console.log("err : ", err);
    }
  };

  const deleteAdLink = async (id) => {
    try {
      const res = await _deleteGoogleAd(id);
      if (res.status === 200) {
        alert("Record deleted Successfully");
        getGoogleAds();
      } else {
        alert("Error Updating Record");
      }
    } catch (err) {
      console.log("err : ", err);
    }
  };

  useEffect(() => {
    previewAdImage();
  }, [adFile]);

  useEffect(() => {
    getGoogleAds();
  }, []);

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              pb: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1>Right Ads </h1>
            <p>Update your Ads here</p>
            <p>Note: You can add only two ads.</p>
            <br />
            <Grid container spacing={2} sx={{ flexGrow: 1, p: 2 }}>
              <Grid xs={12}>
                <label>
                  <b>Add link of your Ad</b>
                </label>
                <br />
                <TextField
                  style={{ marginBlock: "10px", width: "100%" }}
                  label="Link"
                  size="small"
                  id="outlined-end-adornment"
                  sx={{ mb: 1, mr: 1, width: "25ch" }}
                  name="title"
                  onChange={(e) => setAdLink(e.target.value)}
                  value={adLink}
                />
              </Grid>
              <Grid xs={12} sx={{}}>
                <label>{/* <b>Add your article link</b> */}</label>
                <br />
                <div className="preview-upload-img">
                  {adPreviewImg ? <img src={adPreviewImg} alt="" /> : null}
                </div>
                <input
                  type="file"
                  name=""
                  id=""
                  onChange={(e) => setAdFile(e.target.files[0])}
                  ref={fileRef}
                />
              </Grid>
              <Grid
                xs={12}
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {!isAdUpdate ? (
                  <Button
                    style={{
                      marginBlock: "10px",
                      width: "35ch",
                    }}
                    variant="contained"
                    className=""
                    onClick={() => addAdLink()}
                  >
                    Submit
                  </Button>
                ) : (
                  <Stack direction={"row"} spacing={1}>
                    <Button
                      style={{
                        marginBlock: "10px",
                        width: "35ch",
                      }}
                      variant="outlined"
                      className=""
                      onClick={() => {
                        setIsAdUpdate(false);
                        setAdLink("");
                        setAdPreviewImg("");
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      style={{
                        marginBlock: "10px",
                        width: "35ch",
                      }}
                      variant="contained"
                      className=""
                      onClick={() => {
                        updateGoogleAdLink();
                      }}
                    >
                      Update
                    </Button>
                  </Stack>
                )}
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {adsHead?.map((val, index) => {
                      return (
                        <React.Fragment>
                          {/* <TableCell key={index}>SN</TableCell> */}
                          <TableCell key={index}>{val}</TableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adDetails?.map(({ ad_image_url, link, id }, index) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <React.Fragment>
                          <TableCell component="th" scope="row">
                            <div className="ads-image">
                              <img src={ad_image_url} alt="" />
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction={"row"}
                              sx={{ alignItems: "center" }}
                              className="link-parent"
                            >
                              <Tooltip title={link}>
                                <div className="link-truncate">{link}</div>
                              </Tooltip>
                              <span
                                className={`text-copy ${
                                  copied ? "copied" : null
                                }`}
                                onClick={() => handleCopy(link, setCopied)}
                              >
                                {copied ? <DoneIcon /> : <ContentCopyIcon />}
                              </span>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack direction={"row"} spacing={1}>
                              <EditIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setIsAdUpdate(true);
                                  setAdLink(link);
                                  setAdPreviewImg(ad_image_url);
                                  setAdId(id)
                                }}
                              />
                              <DeleteIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  deleteAdLink(id);
                                }}
                              />
                            </Stack>
                          </TableCell>
                        </React.Fragment>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default Ads;
