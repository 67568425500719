export const videoHead = [
    "#",
    "Image",
    "Video Link",
    "Description",
    "Action"
]

export const LinksHead = [
    "Title",
    "Link",
    "Position",
    "Action"
]

export const adsHead = [
    "Image",
    "Link",
    "Action"
]
// For Production Environment
export const GOOGLE_CLIENT_ID = '1002715499697-v8tc48n985s4otv0c70huds31qr14ir9.apps.googleusercontent.com'

// For Local or Stagnig Environment
// export const GOOGLE_CLIENT_ID = '843100866822-5fpf0snnahf03h1ob79k8403o5f9ooo4.apps.googleusercontent.com'

export const voiceName = {
    chrome: "Google UK English Male",
    firefox: "English (America)",
    safari: "com.apple.eloquence.de-DE.Eddy",
    edge: "Microsoft David - English (United States)",
}
export const APP_NAME ='Startup';
export const STRIPE_PRICE_ID = 'price_1PHNiVIpzlkY56XbXLreMerT'