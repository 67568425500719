import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import MasksIcon from "@mui/icons-material/Masks";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { _getCurrentUserDetails } from "../../api/common";
import { useLocation } from 'react-router-dom';
import { APP_NAME } from "../../utils/constants";

function BaseLayout({ isLoggedIn, setIsLoggedIn, isOpenNav ,setIsOpenNav, isInfoMenu, setIsInfoMenu }) {
  const [isAdmin, setIsAdmin] = useState("");
  const location = useLocation();

  
  const navigate = useNavigate();
  const currentUrl = location.pathname;

  const getCurrentUserDetails = async () => {
    const data = {
      token: localStorage.getItem("access_token"),
    };

    if (!data.token) {
      return
    }

    try {
      const res = await _getCurrentUserDetails(data);
      if (res?.status === 200) {
        if (res?.data?.data?.role === "ADMIN") {
          setIsAdmin(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCurrentUserDetails();
  }, [isLoggedIn]);


  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
    localStorage.clear();
    navigate('/login')
  };
  return (
    <>
      <div className="base-wrap">
        <header>
          <div className="header-wrap">
            <div className="title_logo">
              <MasksIcon fontSize="large" className="themeColor"></MasksIcon>{" "}
              <span style={{ fontSize: "18px" }}><b><NavLink to={"/"} style={{ textDecoration: 'none',color:'black' }} >{APP_NAME}</NavLink></b></span>
            </div>

            <div className={`nav-right ${isOpenNav ? 'openMenu' : ' '}` } >
              <div className="link active">
                <NavLink onClick={()=> setIsOpenNav(!isOpenNav)}  to={"/"}>ASK QUESTIONS</NavLink>
              </div>
              {
                isAdmin && isAdmin ? <div className="link d-none-sm">
                  <NavLink to={"/admin"}>ADMIN</NavLink>
                </div>
                  : null
              }
              
              <div className="link aboutRead">
                <NavLink onClick={()=> setIsOpenNav(!isOpenNav)} rel="noopener noreferrer" to={"/about-us"}>About - Please Read!</NavLink>
              </div>
              <div className="link">
                <NavLink onClick={()=> setIsOpenNav(!isOpenNav)} rel="noopener noreferrer" to={"/members"}>MEMBERS </NavLink>
              </div>
              <div className="link">
                <NavLink target="_blank" rel="noopener noreferrer" to={"/termsofuse"}>TERMS OF USE</NavLink>
              </div>
              <div className="link">
                <NavLink target="_blank" rel="noopener noreferrer" to={"/policy"}>PRIVACY </NavLink>
              </div>
              <div className="link">
                <NavLink  rel="noopener noreferrer" to={"/contact"}>CONTACT </NavLink>
              </div>
              <div className="d-none-m">
                {isLoggedIn ? (
                  <div id="login_btn" onClick={() => handleLogout()}>
                    <NavLink underline="none">LOGOUT</NavLink>
                  </div>
                ) : (
                  <div id="login_btn">
                    <NavLink onClick={()=> setIsOpenNav(!isOpenNav)} underline="none" to={"/login"}>
                      LOGIN
                    </NavLink>
                  </div>
                )}
              </div>
              <div className="close_layer" onClick={()=> setIsOpenNav(!isOpenNav)}></div>
            </div>
            <div className="mLogout">
            {
              currentUrl === "/" && <div onClick={()=> setIsInfoMenu(!isInfoMenu)}>
              Info
            </div>
            }
            {isLoggedIn ? (
                <div id="login_btn" onClick={() => handleLogout()}>
                  <NavLink className="btn btn-def">LOGOUT</NavLink>
                </div>
              ) : (
                <div id="login_btn">
                  <NavLink className="btn btn-def" underline="none" to={"/login"}>
                    LOGIN
                  </NavLink>
                </div>
              )}
              <div className="hamburger"  onClick={()=> setIsOpenNav(!isOpenNav)}>
              <span className="base_hamburger">
                <MenuIcon />
              </span>
              <span className="base_cross">
                <CloseIcon />
              </span>
              
              </div>
            </div>
          </div>
        </header>
        <div style={{height: '77px'}}></div>
        <main>
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default BaseLayout;
