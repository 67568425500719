import React from "react";
import { APP_NAME } from "../utils/constants";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h3 className="page-heading">Privacy Policy Overview</h3>
      <ol>
        <li>
          <p>
            <b>Collection of Information:</b>
            We collect personal and sensitive information you provide directly
            to us, such as names, contact details, and payment information, when
            you use our Services, register an account, or by becoming a member
            to get specific ideas and information for your own individual
            health. Additionally, we automatically gather certain information
            like your IP address and device characteristics when you visit our
            Services. We may also receive information from third-party sources,
            including social media platforms and marketing partners.
          </p>
        </li>
        <li>
          <p>
            <b>Use of Information:</b>
            The information we collect is used to provide, improve, and
            administer our Services, manage user accounts, deliver requested
            services, respond to inquiries, and for security purposes. We
            process your information based on consent, to fulfill contractual
            obligations, comply with legal requirements, or for our legitimate
            business interests.
          </p>
        </li>
        <li>
          <p>
            <b>Sharing of Information:</b>
            We may share your information in specific circumstances, such as
            during business transfers or with third-party service providers who
            assist us in offering our Services. Your information will not be
            sold to third parties.
          </p>
        </li>
        <li>
          <p>
            <b>Cookies and Tracking Technologies:</b>
            We use cookies and similar technologies to collect information about
            your interaction with our Services, which helps us enhance user
            experience and service delivery.
          </p>
        </li>
        <li>
          <p>
            <b>Your Rights: </b>
            Depending on your location, you may have rights regarding your
            personal information, including accessing, updating, or requesting
            the deletion of your information. You can exercise these rights by
            contacting us directly. If you are located in the EU or UK, this
            section applies to you.
          </p>
          <p>
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>
          <p>
            <ul>
              <li>
                Consent. We may process your information if you have given us
                permission (i.e., consent) to use your personal information for
                a specific purpose. You can withdraw your consent at any time.
                Learn more about withdrawing your consent.
              </li>
              <li>
                Performance of a Contract. We may process your personal
                information when we believe it is necessary to fulfill our
                contractual obligations to you, including providing our Services
                or at your request prior to entering into a contract with you.
              </li>
              <li>
                Legal Obligations. We may process your information where we
                believe it is necessary for compliance with our legal
                obligations, such as to cooperate with a law enforcement body or
                regulatory agency, exercise or defend our legal rights, or
                disclose your information as evidence in litigation in which we
                are involved.
              </li>
              <li>
                Vital Interests. We may process your information where we
                believe it is necessary to protect your vital interests or the
                vital interests of a third party, such as situations involving
                potential threats to the safety of any person.
              </li>
            </ul>
          </p>
        </li>
        <p>If you are located in Canada, this section applies to you.</p>
        <p>
          We may process your information if you have given us specific
          permission (i.e., express consent) to use your personal information
          for a specific purpose, or in situations where your permission can be
          inferred (i.e., implied consent). You can withdraw your consent at any
          time.
        </p>
        <li>
          <p>
            <b>Changes to Policy:</b>
            We may update this privacy policy as necessary to reflect changes in
            our practices or for other operational, legal, or regulatory
            reasons.
          </p>
        </li>
        <li>
          <p>
            <b>Contact Us:</b>
            For any questions or concerns regarding this privacy policy or your personal information, please contact us directly. By using our Services, you acknowledge you have read and understood this privacy policy.
          </p>
          <p>To contact us please email us at editor@whatfinger.com</p>
        </li>
      
      </ol>
    </div>
  );
}

export default PrivacyPolicy;
