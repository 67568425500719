import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import PasswordTwoToneIcon from '@mui/icons-material/PasswordTwoTone';

export const Listitems =({setCurrentTab})=> (
  <React.Fragment>
    <ListItemButton  onClick={()=>setCurrentTab('links')}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Links" />
    </ListItemButton>
    <ListItemButton onClick={()=>setCurrentTab('rightAds')}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Right Ads" />
    </ListItemButton>
    <ListItemButton onClick={()=>setCurrentTab('leftAds')}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Left Ads" />
    </ListItemButton>
    <ListItemButton onClick={()=>setCurrentTab('password')}>
      <ListItemIcon>
        <PasswordTwoToneIcon />
      </ListItemIcon>
      <ListItemText primary="Change Password" />
    </ListItemButton>
  </React.Fragment>
);

