import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import BaseLayout from "./components/Shared/BaseLayout";
import Home from "./components/Home/Home";
import Authentication from "./components/Authentication/Authentication";
import PrivateRoutes from "./PrivateRoutes";

// admin dashboard
import Dashboard from "./components/Admin/Dashboard";
import UnderConstructionPage from "./components/Shared/UnderConstructionPage";
import Pricing from "./components/Pricing/Pricing";
import PaymentSuccess from "./components/Payment/PaymentSuccess";
import PaymentFailed from "./components/Payment/PaymentFailed";
import { _getCurrentUserDetails } from "./api/common";
import PrivacyPolicy from "./components/Privacy";
import TermsOfUse from "./components/TermsOfUse";
import About from "./components/About";
import Contact from "./components/Contact/Contact";

const AppRoutes = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("access_token") ? true : false
  );
  const [isAdmin, setIsAdmin] = useState(false);
  
  // Open nav
  const [isOpenNav, setIsOpenNav] = useState(false);
  
  // Info box
  const [isInfoMenu, setIsInfoMenu] = useState(false);

  const getCurrentUserDetails = async () => {
    const data = {
      token: localStorage.getItem("access_token"),
    };
    try {
      if(isLoggedIn){
        const res = await _getCurrentUserDetails(data);
        if (res?.status === 200) {
          if (res?.data?.data?.role === "ADMIN") {
            setIsAdmin(true);
          }
        }

      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getCurrentUserDetails();
  }, []);


  return (
    <Router>
      <Routes>
        <Route
          element={
            <BaseLayout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} isOpenNav={isOpenNav} setIsOpenNav={setIsOpenNav} isInfoMenu={isInfoMenu} setIsInfoMenu={setIsInfoMenu} />
          }
        >
          <Route
            path="/login"
            element={<Authentication setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />}
          />
          <Route path="/members" element={<Pricing isLoggedIn={isLoggedIn} />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route element={<PrivateRoutes isLoggedIn={isLoggedIn} />}></Route>
          <Route path="/" element={<Home isLoggedIn={isLoggedIn} isInfoMenu={isInfoMenu} setIsInfoMenu={setIsInfoMenu} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
        </Route>
        <Route path="/admin" element={<Dashboard isAdmin={isAdmin} setIsAdmin={setIsAdmin} setIsLoggedIn={setIsLoggedIn} />} />
        
        <Route path="/twitter" element={<UnderConstructionPage />} />
        <Route path="/success" element={<PaymentSuccess />} />
        <Route path="/failed" element={<PaymentFailed />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
