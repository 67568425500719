import React, { useState,useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import Divider from "@mui/material/Divider";
import MedicalInformationTwoToneIcon from "@mui/icons-material/MedicalInformationTwoTone";
import { _login, _register } from "../../api/common";
import { useNavigate } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import { setData } from "../../utils/cacheService";
import { useGoogleLogin } from '@react-oauth/google';
import { APP_NAME } from "../../utils/constants";

import Login from '../../assets/images/login.png'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Authentication({ setIsLoggedIn, isLoggedIn }) {
  const [value, setValue] = React.useState(0);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  let userAccessToken;
  const navigate = useNavigate();
   
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRegister = async () => {
    if (registerPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const data = {
      email: registerEmail,
      password: registerPassword,
    };

    try {
      const res = await _register(data);
      if (res?.status === 200 && res.data.status) {
        localStorage.setItem("access_token", res?.data?.data?.access_token);
        setIsLoggedIn(true);
        navigate("/members");
      }else{
        setError("Registration failed. Please try again.");
      }
    } catch (error) {
      console.log("err :", error);
      setError("Registration failed. Please try again.");
    }
  };

  const handleLogin = async () => {
    if(userAccessToken){

    }else{
      if (!loginEmail || !loginPassword) {
        setError("Please provide both email and password.");
        return;
      }
    }
    

    const data = {
      email: loginEmail,
      password: loginPassword,
      code:userAccessToken
    };
    try {
      const res = await _login(data);
      if (res.status === 200) {
        localStorage.setItem("access_token", res?.data?.data?.access_token);
        setIsLoggedIn(true);
        if((res?.data?.data?.expiry_date) && !res?.data?.data?.isAdmin){
          navigate("/");
          setData('subscriptionExpireTime',res?.data?.data?.expiry_date);
        }else if(localStorage.getItem('accessAdmin') && res?.data?.data?.isAdmin ){
          navigate("/admin")
        }
        else{
          navigate("/members");
          setData('subscriptionExpireTime',res?.data?.data?.expiry_date);
        }
      }
    } catch (error) {
      console.log("err :", error);
      setError("Login failed. Please check your credentials.");
    }
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      userAccessToken = codeResponse.access_token;
      handleLogin();
    },
    onError: (error) => console.log('Login Failed:', error)
});


  return (
    <>
      <section className="login">
        <Container >
          <Box className="loginInner">
            <div className="login_image">
              <img src={Login} alt="" className="w-100" />
            </div>
            <div className="authPage">
              <Box sx={{ width: "100%", marginTop: 0 }}>
                <Stack direction={"row"} justifyContent={"center"}>
                  <Typography variant="h5" gutterBottom sx={{ color: "" }}>
                    
                    <b>{APP_NAME}</b>
                  </Typography>
                </Stack>

                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      variant="fullWidth"
                    >
                      <Tab label="LOGIN" {...a11yProps(0)} />
                      <Tab label="CREATE ACCOUNT" {...a11yProps(1)} />
                    </Tabs>
                  </Box>

                  <CustomTabPanel value={value} index={0}>
                    <Box>
                      <Stack spacing={2}>
                        <Button variant="outlined" startIcon={<GoogleIcon />} onClick={() => login()}>
                          Login with Google
                        </Button>
                        <Divider>or</Divider>
                        <TextField
                          id="outlined-email"
                          placeholder="Enter your email"
                          label="Email"
                          onChange={(e) => setLoginEmail(e.target.value)}
                          type="email"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <TextField
                          id="outlined-password"
                          placeholder="Enter your password"
                          label="Password"
                          onChange={(e) => {
                            setLoginPassword(e.target.value);
                          }}
                          type="password"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <Button
                          className="btn-theme"
                          variant="contained"
                          onClick={handleLogin}
                        >
                          LOGIN
                        </Button>

                        <Button variant="text">Forgot your password?</Button>
                      </Stack>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <Box>
                      <Stack spacing={2}>
                        <Button variant="outlined" startIcon={<GoogleIcon />}  onClick={() => login()}>
                        Login with Google
                        </Button>
                        <Divider>or</Divider>
                        <TextField
                          id="outlined-email"
                          placeholder="Enter your email"
                          onChange={(e) => setRegisterEmail(e.target.value)}
                          label="Email"
                          type="email"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <TextField
                          id="outlined-password"
                          placeholder="Enter password"
                          label="Password"
                          type="password"
                          onChange={(e) => setRegisterPassword(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <TextField
                          id="outlined-confirm-password"
                          placeholder="Confirm password"
                          label="Confirm Password"
                          type="password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <Button
                          className="btn-theme"
                          variant="contained"
                          onClick={handleRegister}
                        >
                          SIGN UP
                        </Button>
                      </Stack>
                    </Box>
                  </CustomTabPanel>
                </Box>
                {error && (
                  <Typography variant="body2" color="error" align="center">
                    {error}
                  </Typography>
                )}
              </Box>
            </div>
          </Box>
        </Container>
      </section>
    </>
  );
}

export default Authentication;
