import React from "react";

const About = () => {
  return (
    <div>
      <div className="privacy-policy">
        <h3 className="page-heading">About Page</h3>
        <p>Welcome to Startup AI Buddy. We're here to help you succeed. Being a member here gets you many benefits if you have a website. Most importantly on top of having A.I. answer any question you have, to help with marketing ideas, text, and anything really, you also can have instant content for your website direct from Whatfinger News, one of the top aggregate news sites on the net. You can see some details under the Start Up chat in the main section.
With our help, you not only have the combined knowledge of all top business people to ever live, but you also get help and results to help forge your website into a working and profitable business.
Members Page - $75 per month - change from the $25
For Terms Of Use Page</p>
<p><b><u>Terms of Use</u></b></p>
<p><b>Effective Date:</b> 1st June 2024</p>
<p>Welcome to Startup AI Buddy, operated by WF Media Inc. ("Company," "we," "our," or "us"). By accessing or using our website located at [Website URL] (the "Site"), you agree to comply with and be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, please do not use the Site.</p>
<ol>
    <li><b>Acceptance of Terms</b>
        <p>By accessing and using the Site, you accept and agree to be bound by these Terms and all applicable laws and regulations. If you do not agree with any part of these Terms, you are prohibited from using or accessing this Site.</p>
    </li>
    <li><b>Changes to Terms</b>
        <p>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting. Your continued use of the Site following the posting of changes constitutes your acceptance of those changes. Please review these Terms periodically for updates.</p>
    </li>
    <li><b>Use of the Site</b>
        <p><b>You agree to use the Site only for lawful purposes and in accordance with these Terms. You agree not to use the Site:</b></p>
        <ul>
            <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
            <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way.</li>
            <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain</li>
        </ul>
    </li>
    <li><b>Intellectual Property Rights</b>
        <p>The Site and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by WF Media Inc., its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
    </li>
    <li><b>User Contributions</b>
        <p>The Site may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and other interactive features (collectively, "Interactive Services") that allow users to post, submit, publish, display, or transmit to other users or other persons (hereinafter, "post") content or materials (collectively, "User Contributions") on or through the Site.</p>
        <p>All User Contributions must comply with the Content Standards set out in these Terms.</p>
    </li>
    <li><b>Content Standards</b>
        <p>These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not:</p>
        <ul>
            <li>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
            <li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</li>
            <li>Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.</li>
            <li>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms and our Privacy Policy.</li>
        </ul>
    </li>
    <li><b>Termination</b>
       <p>We may terminate or suspend your access to all or part of the Site for any reason, including without limitation, any violation of these Terms. Upon termination, your right to use the Site will immediately cease.</p> 
    </li>
    <li><b>Disclaimer of Warranties</b>
        <p>The Site is provided on an "as is" and "as available" basis. WF Media Inc. makes no representations or warranties of any kind, express or implied, as to the operation of the Site, or the information, content, materials, or products included on the site</p>
        <p>If you have any questions, you can arrange a call by emailing us first at editor@whatfinger.com. You can send a snail mail to:</p>
        <p><b>WF Media Inc, <br /> 6805 Lebanon Rd, Suite 512, <br />Frisco Texas 75034</b></p>
    </li>
    
</ol>

      </div>
    </div>
  );
};

export default About;
