import { endPoints } from "./endPoint";
import { httpRequest } from "./httpRequest";
import axios from 'axios';

export const _chatWithDoctor = async ({ data, id, token }) => {
  return await httpRequest({
    method: "POST",
    url: `${endPoints.conversation}`,
    data: data,
    loginSession: id && id?.sessionId,
    token: token && token
  });
};

export const _getVideoLinks = async () => {
  return await httpRequest({
    method: "GET",
    url: `${endPoints.videoLinks}`,
  });
};

export const _login = async (data) => {
  return await httpRequest({
    method: "POST",
    url: `${endPoints.login}`,
    data: data,
  });
};

export const _register = async (data) => {
  return await httpRequest({
    method: "POST",
    url: `${endPoints.register}`,
    data: data,
  });
};

export const _followUpMessages = async (data) => {
  return await httpRequest({
    method: "POST",
    url: `${endPoints.followUpMessages}`,
    data: data,
  });
};

export const _getCurrentUserDetails = async (data) => {
  return await httpRequest({
    method: "GET",
    url: `${endPoints.currentUserDetails}`,
    data: data,
  });
};

export const _addVideos = async (data) => {
  return await httpRequest({
    method: "POST",
    url: `${endPoints.addVideoLink}`,
    data: data,
    contentType: 'multipart/form-data'
  });
};

export const _addArticleLinks = async (data) => {
  return await httpRequest({
    method: "POST",
    url: `${endPoints.addArticleLink}`,
    data: data,
  });
};

export const _updateVideoLink = async (data, id) => {
  return await httpRequest({
    method: "PATCH",
    url: `${endPoints.updateVideoLink}/${id}`,
    contentType: 'multipart/form-data',
    data: data,
  });
};

export const _deleteVideoLink = async (id) => {
  return await httpRequest({
    method: "DELETE",
    url: `${endPoints.deleteVideoLink}/${id}`,
  });
};
export const _updateLink = async (data, id) => {
  return await httpRequest({
    method: "PATCH",
    url: `${endPoints.updateLink}/${id}`,
    data: data,
  });
};
export const _deleteLink = async (id) => {
  return await httpRequest({
    method: "DELETE",
    url: `${endPoints.deleteLink}/${id}`,
  });
};

export const getSession =  async (data) => {
  return await httpRequest({
   method:'POST',
   url:`${endPoints.stripeSession}`,
   data:data
  })
}

export const _addGoogleAd =  async (data) => {
  return await httpRequest({
   method:'POST',
   url:`${endPoints.addGoogleAd}`,
   contentType: 'multipart/form-data',
   data:data
  })
}

export const _updateGoogleAd =  async (data, id) => {
  return await httpRequest({
   method:'PATCH',
   url:`${endPoints.updateGoogleAds}/${id}`,
   contentType: 'multipart/form-data',
   data:data
  })
}

export const _deleteGoogleAd =  async (id) => {
  return await httpRequest({
   method:'DELETE',
   url:`${endPoints.deleteGoogleAds}/${id}`,
  //  contentType: 'multipart/form-data',
  //  data:data
  })
}

export const _getGoogleAds = async (position) => {

  return await httpRequest({
    method: "GET",
    url: `${endPoints.getGoogleAds}?position=${position}`,
  });
};

export const _getAudio = () => {
  const url = endPoints.getAudio;

  return axios.get(url, {
      responseType: 'blob',
  });    
};

export const _getTextAudio = (data) => {
  const url = endPoints.getTextAudio;

  return axios.post(url, data, {
      responseType: 'blob',
  });    
};


export const _getSessionMessages = async (sessionId) => {
  return await httpRequest({
    method: "GET",
    url: `${endPoints.getSessionMessages}`,
    sessionId: sessionId && sessionId
  });
};

export const getsubscriptionStatus = async () => {
  return await httpRequest({
    method: "GET",
    url: `${endPoints.subscriptionStatus}`
  });
};

export const _browseLinks = async (data) => {
  return await httpRequest({
    method: "GET",
    url: `${endPoints.browseLinks}`,
    params: data
  });
};

export const _changePassword = async (data) => {
  return await httpRequest({
    method: "POST",
    url: `${endPoints.changePassword}`,
    data: data,
  });
};
