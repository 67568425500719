import React, { useEffect, useState } from "react";
import "./Dashboard.scss";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button, Stack } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from 'react-router-dom';
import {
  _getCurrentUserDetails,
  _getVideoLinks,
  _addVideos,
  _addArticleLinks,
  _updateVideoLink,
  _deleteVideoLink,
  _updateLink,
  _deleteLink,
} from "../../api/common";
import { Listitems } from "./Listitems";
import Ads from "./Ads";
import Links from "./Links";
import Videos from "./Videos";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LeftAds from "./LeftAds";
import ChangePassword from "./ChangePassword";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const Dashboard = ({ isAdmin, setIsAdmin, setIsLoggedIn }) => {
  const [open, setOpen] = React.useState(true);
  const [currentTab, setCurrentTab] = useState("links");
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const location = useLocation();
  const accessAdmin = location.pathname.split('/').filter(segment => segment !== '');
  localStorage.setItem('accessAdmin', accessAdmin)
  const getCurrentUserDetails = async () => {
    const data = {
      token: localStorage.getItem("access_token"),
    };
    try {
      const res = await _getCurrentUserDetails(data);
      if (res?.data?.status === true) {
        if (res?.data?.data?.role === "ADMIN") {
          setIsAdmin(true);
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    getCurrentUserDetails();
  }, []);

  return (
    <>
      {isAdmin && (
        <>
          {isAdmin ? (
            <ThemeProvider theme={defaultTheme}>
              <ToastContainer theme="colored" />
              <Box sx={{ display: "flex" }}>
                <CssBaseline />

                <AppBar position="absolute">
                  <Toolbar
                    sx={{
                      pr: "24px", // keep right padding when drawer closed
                    }}
                  >
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={toggleDrawer}
                      sx={{
                        marginRight: "36px",
                        ...(open && { display: "none" }),
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      Admin
                    </Typography>
                    <Button color="inherit" onClick={() => handleLogout()}>
                      <Badge badgeContent={0} color="secondary">
                        <ExitToAppIcon />
                      </Badge>
                      {" "}{"Logout"}{" "}
                    </Button>
                  </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                  <Toolbar
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      px: [1],
                    }}
                  >
                    <IconButton onClick={toggleDrawer}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </Toolbar>
                  <Divider />
                  <List component="nav">
                    <Listitems setCurrentTab={setCurrentTab} />
                  </List>
                </Drawer>
                <Box
                  component="main"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                  }}
                >
                  <Toolbar />
                  <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    {currentTab === "links" ? (
                      <Links />
                    ) : currentTab === "rightAds" ? (
                      <Ads />
                    ) : currentTab === "leftAds" ? (
                      <LeftAds />
                    ) : (
                      <ChangePassword />
                    )}
                  </Container>
                </Box>
              </Box>
            </ThemeProvider>
          ) : (
            <Navigate to="/" />
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
