import React from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import './Contact.scss'
import { APP_NAME } from "../../utils/constants";

const ContactUs = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  return (
    <section className="contact">
      <Container maxWidth="lg">
        <div className="contactInner">
          <div>
            <Typography variant="h4" className="text-center">
              Contact Us
            </Typography>
            <br />
            {/* <p>If you need to contact us for any reason, please email us at <a href="mailto:contact@startup.com">contact@startup.com</a> using the form below</p> */}
          </div>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="text-center">

              
                <br />
                For initial contact, please email us at editor@whatfinger.com - tell us your time zone, phone number and name and email, as well as best times to call. <br />We return calls usually within 24hrs.
                 <br /> <br />Mal Antoni - Main Editor at Whatfinger News, the owner of Startup.
                {/* <p>If you need to contact us for any reason, please email us at <a href="mailto:contact@startup.com">contact@startup.com</a> </p>
                <br />
                <p>Or </p>
                <br />
                <p>You can send snail mail as well at the following address:</p>
                <br />
                <address>
                   <h3> {APP_NAME} <br />  w3villa <br/> Noida sector 59</h3>
                </address> */}
            </div>
            </Grid>
            {/* <Grid item xs={6}>
              <Box className="">
                <form className="" onSubmit={handleSubmit}>
                  <TextField
                    className="mt-20"
                    label="Name"
                    variant="outlined"
                    fullWidth
                  />
                  <br />
                  <br />
                  <TextField
                    className="mt-20"
                    label="Phone Number"
                    type="tel"
                    variant="outlined"
                    fullWidth
                  />
                  <br />
                  <br />
                  <TextField
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                  />
                  <br />
                  <br />
                  <TextField
                    className="mt-20"
                    label="Message"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                  />
                  <br />
                  <br />
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn-theme mt-20"
                    fullWidth
                  >
                    Send Email
                  </Button>
                </form>
              </Box>
            </Grid> */}
          </Grid>
        </div>
      </Container>
      <div></div>
    </section>
  );
};

export default ContactUs;
